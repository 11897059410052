<template>
<div>
    <div class="container my-3">
        <h4 class="row" style="margin-top:20px">INDUSTRIAL ICONS MANAGER</h4>
        <br>
            <div class="row">
            <div class="col-8" v-bind:key="this.componentKey">
                
                <div class="row">
                    <div class="col-2 text-secondary text-center">Preview</div>
                    <div class="col-3 text-secondary">Name</div>
                    <div class="col-3 text-secondary">Title</div>
                    <div class="col-2 text-secondary text-center">Active</div>
                    <div class="col-1 text-secondary" v-if="canManageIcons"></div>
                </div>
                <div class="row" v-for="(icon, index) in icons" v-bind:key="index" :print="log(icon.toDelete)">
                    <div class="col-2 text-center vertical-center"> <img v-bind:src="getImageUrl(icon)" width="35px"/> </div>
                    <div class="col-3 text-break "><p class="no-margin">{{icon.SymbolID}}</p></div>
                    <div class="col-3 text-break "><p class="no-margin">{{icon.Title}}</p></div>
                    <div class="col-2 text-center vertical-center">
                        <b-form-checkbox
                            @change="isActive(icon)"
                            v-model="icon.isActive"
                            switch
                            size="lg"
                            :disabled="!canManageIcons"
                            style="transform: scale(1.5);"  
                        />
                    </div>
                    <div v-if="(!icon.Filepath.includes('default')) && canDeleteIcons">
                        <div class="col-1 text-center verticalCenterBox" @click="icon.toDelete=true">
                            <b-button
                                variant="dark"
                                class="d-flex justify-content-center align-items-center p-0"
                                style="width: 35px; height: 35px;"
                            >
                                <i class="bi bi-trash-fill" style="font-size: 1.25rem;"></i>
                            </b-button>
                        </div>
                        
                        <div v-if="icon.toDelete">
                            <stackAccept :id="icon.id" :name="icon.SymbolID" :elementType="'Icon'" @boolDelete="deleteIcon" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4" v-if="canManageIcons">
                <dragAndDropIcons :token="token" :Orga="Orga" @object_adeed="addObjectToList" />
            </div>
            </div>
        </div>
        <div>
            <p class="lead" style="font-size:10px">
                IMPORTANT<br>
                *Upload an .svg, .png or .jpg logo format. Your icon should be cropped and centered, with a transparent or white background. <br>
                *Minimum size 900x300px
            </p>
        </div>

        <div>
            <stack-modal :show="showError" title="Error 💥" :saveButton=saveButtonDefaults :cancelButton=cancelButtonDefaults @close="showError=false">
                <h3>{{stackMessage}}</h3>
            </stack-modal>
        </div>
    </div>
</template>


<script>
    import axios from 'axios'
    import dragAndDropIcons from '@/components/Components/dragAndDropIcons'
    import StackModal from '@innologica/vue-stackable-modal'
    import stackAccept from '@/components/Components/stackAccept.vue'
    export default{
        data(){
            return{
                API : 'https://localhost'+'/api',
                url: null,
                icons: [],
                componentKey : 0,
                saveButtonDefaults:{
                    title: 'Ok',
                    visible: false,
                    btnClass: {'btn btn-primary': true,
                    onClick:"return sampleFunction()"}
                },
                cancelButtonDefaults:{
                    title: 'Ok',
                    visible: true,
                    btnClass: {'btn btn-secondary': true}
                },
                showError:false,
                stackMessage:""
            }
        },
        mounted(){
            var domain=self.location.host;
            if(domain =="localhost" || domain=="giri" || domain=="127.0.0.1"){
                console.log("On premise server");
                this.API="https://localhost/api";
            }else{
                console.log("Cloud server");
                this.API="https://"+self.location.host+"/api"
            }
            this.getIcons();
        },
        computed: {
            canManageIcons() {
                return this.$accessControlStore.permissions.MANAGE_ICON;
            },
            canDeleteIcons() {
                return this.$accessControlStore.permissions.DELETE_ICON;
            }
        },
        components: {
            "dragAndDropIcons" : dragAndDropIcons,
            StackModal,
            'stackAccept':stackAccept
        },
        props:{
            token: String,
            Orga:String
        },
        methods: {
            fileChange(value) {
                this.url = URL.createObjectURL(value);
            },
            forceRerender(){
                this.componentKey += 1;
            },
            getIcons:function(){
                this.icons=[];
                axios
                .get(this.API + '/Industrial_Icons?access_token='+ this.token + "&filter[where][organizationID]=" + this.Orga)
                .then(response => {
                    var info = response.data;
                    //show only patterns that are not delete in UI
                    info.forEach(icon => {
                        icon.toDelete=false;
                        if(!icon.deleted){
                            this.icons.push(icon);
                        }
                    });
                });
            },
            getImageUrl:function(icon){
                const splt = icon.SymbolID.split('.');
                const fle = icon.id +"."+ splt[splt.length-1];
                let imageFormat= icon.SymbolID.substring(icon.SymbolID.indexOf('.') + 1);
                console.log("this format is : " +imageFormat)
                console.log(icon);
                if (icon.Filepath.includes('default')) {
                    return `${this.API}/${icon.Filepath}/download/${icon.SymbolID}?access_token=${this.token}`;
                } 
                else {
                    return `${this.API}/${icon.Filepath}/download/${icon.id}.${imageFormat}?access_token=${this.token}`;
                }
            },
            deleteIcon:function(del, id){
                let icon, ind;
                this.icons.forEach((i, indx)=>{
                    if(i.id == id){
                        icon = i;
                        ind = indx;
                    }
                });

                if(!del){
                    this.icons[ind].toDelete=false;
                    return;
                }
                const simbId = icon.SymbolID.split(".");
                console.log(simbId);
                axios.delete(this.API + "/Files/giri_industrial_icons/files/"+icon.id+"."+simbId[simbId.length-1]+"?access_token="+this.token).then(
                    (res)=>{
                        axios.delete(this.API + "/Industrial_Icons/"+icon.id+"?access_token="+this.token).then(
                            (res)=>{
                                this.icons.splice(ind,1);
                            },
                            (err)=>{
                                this.showError = true;
                                this.stackMessage = "Error deleting icon from DB"
                            }
                        )
                    },
                    (err)=>{

                        this.showError = true;
                        this.stackMessage = "Error deleting icon from Server"
                    }
                )
                //this.forceRerender();
            },
            addObjectToList(object){
                this.icons.push(object);
                this.getIcons();
                this.forceRerender();
                
                
            },
            isActive(icon) {
                axios
                    .post(this.API + '/Industrial_Icons/' + icon.id + '/replace?access_token=' + this.token, icon)
                    .then(res => {
                        console.log('Icon status updated');
                    })
                    .catch(err => {
                        console.error('Error updating icon status', err);
                    });
            },
            log(item)
            {
                console.log(item)
            }
        }
    }

</script>

<style lang="css" scoped>
.vertical-center{
    display:flex;
    align-items: center;
    justify-content: center;
}
.no-margin{
    margin-top: .5rem!important;
    margin-bottom: .5rem!important;
}
.text-15{
    margin-left: -15px
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
.switch{
    margin: 0px !important;
}

input:checked + .slider {
  background-color: #156E8E;
}

input:focus + .slider {
  box-shadow: 0 0 1px #156E8E;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
