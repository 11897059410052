<template>
    <div>
        <button align="right" class="buttn" v-on:click="helpStack=true">
                Help
                <img src="../../../../assets/help.png" alt="" style="vertical-align:top; max-width:20px; max-height:20px">
        </button>
        <div class="dragContainer" id="app">
            <div class="filesContainer" id="FilesContainer" @drop="drop" @dragover="dragover" @dragleave="dragleave">
                <input hidden type="file" multiple name="fields[assetsFieldHandle][]" :forceFallback="true" id="assetsFieldHandle" @change="onChange" ref="file" />
                <label for="assetsFieldHandle" class="block cursor-pointer">
                    <div class="dragText">
                        <img src="../../../../assets/Upload.png" width="50" height="50">
                        <br>
                        <span class="underline text-secondary">Drag and drop the CSV table file to upload multiple users.</span>
                    </div>
                </label>
            </div>
        </div>
        

        <stack-modal :show="onDoneUpload" @close="onDoneUpload=false" :saveButton="saveButton" :cancelButton="cancelButton">
            <h4 class="text-secondary text-center">{{stackMessage}}</h4>
            <div v-if="uploadSummary.total > 0">
                <div v-if="uploadSummary.success > 0" class="success-message">
                    <i class="fas fa-check-circle"></i>
                    <span>{{ uploadSummary.success }} account(s) successfully created</span>
                </div>

                <div v-if="hasErrors" class="error-section">
                    <p class="error-title">
                        <i class="fas fa-exclamation-triangle"></i>
                        Accounts that could not be created:
                    </p>

                    <div v-if="uploadSummary.existing > 0" class="error-group">
                        <details>
                            <summary>{{ uploadSummary.existing }} user(s) already existed and have not been changed</summary>
                            <div class="dropdown-content">
                                <ul>
                                    <li v-for="email in uploadSummary.existingUsers" :key="email">{{ email }}</li>
                                </ul>
                            </div>
                        </details>
                    </div>

                    <div v-if="uploadSummary.invalidRole > 0" class="error-group">
                        <details>
                            <summary>{{ uploadSummary.invalidRole }} user(s) had invalid roles</summary>
                            <div class="dropdown-content">
                                <ul>
                                    <li v-for="user in uploadSummary.invalidRoleUsers" :key="user.email">
                                        {{ user.email }} (invalid role: {{ user.type }})
                                    </li>
                                </ul>
                            </div>
                        </details>
                    </div>

                    <div v-if="uploadSummary.otherErrors > 0" class="error-group">
                        <p>{{ uploadSummary.otherErrors }} user(s) could not be created due to other errors</p>
                    </div>
                </div>
            </div>
            <div v-if="errorList.length >= 1">
                <p>Please check</p>
                <ul style="line-height:1">
                    <li><p>Whether the table is formatted correctly</p></li>
                    <li><p>If all e-mails addresses are written correctly</p></li>
                    <li><p>That none of the emails already have an account</p></li>
                    <li><p>The file is not bigger than 30 Mb</p></li>
                </ul>
            </div>
        </stack-modal>

        <stack-modal :show="helpStack" @close="helpStack=false" :saveButton="saveButton" :cancelButton="cancelButton">
            <h3>Create a CSV table</h3>
            <p>
                To create a large number of new accounts, you can upload a CSV list containing the new users. Please download and use <a href="#" @click.prevent="downloadTemplate" style="text-decoration: underline;">this template</a> to get started.
            </p>
            <p>
                Csv files can be created with e.g. Excel, Google Sheets or the standard editor.
            </p>

            <div class="container">
                <div class="row">
                    <div class="col">
                        <img src="../../../../assets/exampleEditor.JPG.jpg" alt="">
                        <p></p>
                    </div>
                    <div class="col">
                        <img src="../../../../assets/exampleGoogleSheets.JPG.jpg" alt="">
                    </div>
                </div>
            </div>
        </stack-modal>
    </div>
</template>

<script>
import { log } from 'three';
import StackModal from '@innologica/vue-stackable-modal'
import axios from 'axios'
    export default{
        components: {
            StackModal
        },
        data() {
            return{
                filelist: [], // Store our uploaded files
                errorList:[],
                API: 'https://localhost'+'/api',
                onDoneUpload: false,
                saveButton:{visible: false},
                cancelButton:{
                    title: 'Ok',
                    visible: true,
                    btnClass: {'btn btn-primary ': true}
                },
                correctUploaded:0,
                errorUploaded:0,
                stackMessage:"Uploading Files",
                cancelUpload: false,
                helpStack: false,
                uploadSummary: {
                    total: 0,
                    success: 0,
                    existing: 0,
                    invalidRole: 0,
                    otherErrors: 0,
                    existingUsers: [],
                    invalidRoleUsers: [],
                },
                validUserTypes: ["werker", "manager"],
            }
        },
        beforeMount(){
            axios.get(this.$address+'api/utilities/serverFeatures').then(res=>{                 
                this.serverFeatures = res.data.result ;
                console.log(this.serverFeatures)
                console.log(typeof this.serverFeatures.SSO)
            })
        },
        props:{
            token:String,
            Orga:String
        },
        computed: {
            hasErrors() {
                return this.uploadSummary.existing > 0 || 
                       this.uploadSummary.invalidRole > 0 || 
                       this.uploadSummary.otherErrors > 0;
            }
        },
        methods: {
            onChange() {
                this.filelist = Object.entries(this.$refs.file.files).map((a)=>{return a[1]});
                this.uploadList();
            },
            drop(event) {
                event.preventDefault();
                this.$refs.file.files = event.dataTransfer.files;
                this.onChange(); // Trigger the onChange event manually
            // Clean up
                event.currentTarget.classList.add('bg-gray-100');
                event.currentTarget.classList.remove('bg-green-300');
            },
            dragover(event) {
                event.preventDefault();
                // Add some visual fluff to show the user can drop its files
                if (!event.currentTarget.classList.contains('bg-green-300')) {
                    event.currentTarget.classList.remove('bg-gray-100');
                    event.currentTarget.classList.add('bg-green-300');
                }
            },
            dragleave(event) {
                    // Clean up
                    event.currentTarget.classList.add('bg-gray-100');
                    event.currentTarget.classList.remove('bg-green-300');
                },
            uploadList(){
                this.cancelButton.title = "Cancel"

                this.stackMessage = "Uploading Users";
                if(this.filelist.length <= 0){  
                    this.stackMessage = "Please check your .csv file of errors.";
                    return;
                }
                
                this.onDoneUpload = true;

                this.errorList = [];

                const sizeLimit = Math.pow(1024,3);

                /**
                 * @fullCsv {string = } Full users table text
                 */
                const getUsersList = (fullCsv) => {
                    let rows = fullCsv.split('\r\n');
                    if (rows.length <= 1) {
                        rows = fullCsv.split('\n');
                    }
                    console.log(rows);
                    if (rows.length < 2)
                        return [];
                    
                    // Remove blank spaces from column titles
                    const head = rows.shift().split(",").map(title => title.trim());
                    
                    // Check for trimmed column titles
                    if (head.length == 2 ? (head[0] != "email" || head[1] != "user type") : true)
                        return [];

                    const users = rows.map(user => {
                        // Remove blank spaces from values
                        const usrSpl = user.split(",").map(value => value.trim());

                        if(usrSpl.length<2)
                            return {
                                user:usrSpl[0],
                                type:"error",
                                error:"Error in row Length"
                            }

                        if(usrSpl.length>2)
                            return {
                                user:usrSpl[0],
                                type:usrSpl[1],
                                error:"Error in row lenght"
                            }

                        const temp = usrSpl[0].split("@");
                        if(temp.length != 2? true: temp[1].split(".").length <= 1)
                            return {
                                user:usrSpl[0],
                                type:usrSpl[1],
                                error:"Error in email"
                            }

                        if(!this.validUserTypes.includes(usrSpl[1]))
                            return{
                                user:usrSpl[0],
                                type:usrSpl[1],
                                error: "Error in user type"
                            }
                        
                        return{
                            user:usrSpl[0],
                            type:usrSpl[1]
                        }
                    });

                    console.log(users);
                    return users;
                }

                const onUploadError = (res, info)=>{
                    if (res.response && res.response.data && res.response.data.error) {
                        const errorMessage = res.response.data.error;
                        console.log(res.response.data.error)
                        if (errorMessage.statusCode==502 || errorMessage.statusCode==501) {
                            this.uploadSummary.existing++;
                            this.uploadSummary.existingUsers.push(info.email);
                        } else {
                            this.uploadSummary.otherErrors++;
                        }
                    } else {
                        this.uploadSummary.otherErrors++;
                    }
                    this.errorList.push(info.email);
                }

                const getFile = (uploadFileFunc)=>{
                    if(this.filelist.length >0){
                        let fr = new FileReader();
                        fr.onload = uploadFileFunc;
                        fr.readAsText(this.filelist.shift());
                    }else{
                        this.cancelButton.title = "Ok"
                        this.stackMessage = "CSV import report";
                        return;
                    }
                }

                if(this.filelist.length <= 0){
                    return;
                }
                
                const uploadFile = (event)=>{
                    if(!this.onDoneUpload)
                        return;

                    const fullUsers = getUsersList(event.target.result);
                    const validUsers = fullUsers.filter(us => {
                        if (us.hasOwnProperty("error")) {
                            if (us.error === "Error in user type") {
                                this.uploadSummary.invalidRole++;
                                this.uploadSummary.invalidRoleUsers.push({ email: us.user, type: us.type });
                            }
                            return false;
                        }
                        return true;
                    });

                    if(validUsers <= 0){
                        this.errorList.push({"name":"Please check your .csv files for errors."})
                        this.stackMessage = "Something is wrong";
                        return;
                    }

                    fullUsers.forEach(us => {
                        if(us.hasOwnProperty("error"))
                            this.errorList.push(us.user);
                        }
                    );
                    
                    let toUpload = validUsers.map(user=>{
                        return {
                            "usertype": user.type.trim(),
                            "organisationID": this.Orga,
                            "email": user.user.trim(),
                            "emailVerified": true,
                            "password": (Math.floor(Math.random() * 8999) + 1000).toString(),
                            "csv":this.serverFeatures.SKIP_CSV 
                        }
                    });

                    const uploadList = ()=>{

                        if(toUpload.length>0){
                            axios.post(this.API + '/UserAccounts?access_token='+this.token, toUpload[0]).then(
                            (res)=>{
                                console.log(res.data);
                                this.$emit("listNewUser", res.data);
                                this.uploadSummary.success++;
                                this.uploadSummary.total++;
                                toUpload.shift();
                                uploadList();
                            },
                            (err)=>{
                                this.uploadSummary.total++;
                                onUploadError(err, toUpload.shift())
                                uploadList();
                            });
                        }else{
                            this.stackMessage = this.hasErrors ? "Upload complete with some errors." : "Upload successful!";
                            getFile(uploadFile);
                        }
                    }
                    uploadList();
                }
                
                // Reset upload summary before starting new upload
                this.uploadSummary = {
                    total: 0,
                    success: 0,
                    existing: 0,
                    invalidRole: 0,
                    otherErrors: 0,
                    existingUsers: [],
                    invalidRoleUsers: [],
                };
                
                getFile(uploadFile);
            },
            downloadTemplate() {
                const csvContent = `email,user type
manager@example.com,manager
werker@example.com,werker
orgadmin@example.com,orgAdmin`;

                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement("a");
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", "user_template.csv");
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        },
        mounted(){
            var domain=self.location.host;
            if(domain =="localhost" || domain=="giri" || domain=="127.0.0.1"){
                console.log("On premise server");
                this.API="https://localhost/api";
            }else{
                console.log("Cloud server");
                this.API="https://"+self.location.host+"/api"
            }
        }
    }
</script>

<style lang="css" scoped>
    .dragContainer{
        flex:content; 
        align-items:center; 
        justify-content: center;
        text-align:center;
    }

    .filesContainer{
        border-style: dashed;
        border-color: rgb(116, 116, 116);
        border-width: 2px;
        border-radius: 10px;
    }

    .filesContainer:hover{
        border-style: dashed;
        border-color: rgb(146, 146, 146);
        border-width: 2px;
        border-radius: 10px;
        background-color: rgb(237, 247, 255);
    }

    .dragText{
        padding-top: 4vh;
        padding-bottom: 4vh;
    }

    .buttn{
        background-color: transparent;
        border: none;
        text-align: center;
        float: right;
        color: gray;
        margin-top: 4px;
    }

    .success-message {
        color: #28a745;
        margin-bottom: 15px;
    }

    .error-section {
        margin-top: 20px;
    }

    .error-title {
        color: #dc3545;
        margin-bottom: 10px;
    }

    .error-group {
        margin-bottom: 15px;
    }

    .fas {
        margin-right: 5px;
    }

    details {
        cursor: pointer;
    }

    summary {
        font-weight: bold;
    }

    .dropdown-content {
        max-height: 150px;
        overflow-y: auto;
        border: 1px solid #ccc;
        padding: 10px;
        margin-top: 5px;
    }

    .dropdown-content ul {
        list-style-type: none;
        padding-left: 0;
    }

    .dropdown-content li {
        margin-bottom: 5px;
    }
</style>