<template><!--LOGIN SECTION-->
    <div v-if="canManageWebPassword">  
        <form class="wkPasswordForm" id="loginForm" style="display:block;" >
                    <h1>Instruction Password</h1>
                    <br>    
                    <input type="password" class="form-control" placeholder="Password" v-model="password">
                    <br>
                    <button type="button" class="btn btn-primary btn-block" id="loginBtn" v-on:click="setPassword" >Set Password</button>
                    <button type="button" class="btn btn-danger btn-block" id="loginBtn" v-on:click="deletePassword" >Delete Password</button>

                </form>
    </div>
    <div v-else>
        <div class="alert alert-warning d-flex my-3" role="alert">
            <div class="mr-2">
                <i class="bi bi-exclamation-triangle-fill mr-1"></i>
            </div>
            <div>
                <p class="mb-1">
                    You do not have sufficient permissions to access this feature.
                </p>
                <p class="mb-0">
                    Please contact your administrator if you believe this is an error or if you need access.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import StackModal from '@innologica/vue-stackable-modal'
    export default{    
        components: { StackModal },
        data(){
            return{
                baseURL: `${this.$address}api`,
                password:""
            }
        },
        computed: {
            canManageWebPassword() {
                return this.$accessControlStore.permissions.MANAGE_WEB_PASSWORD;
            },
        },
        mounted(){

        },     
        props:{
            isLoggedIn:Boolean,
            usertype:String,
            token:String,
            orgaID:String,
            //orgs:Array
        },
        methods:{
            setPassword(){
            axios.post(`${this.baseURL}/Workflows/setWorkflowPassword?access_token=${this.token}`, {
                params: {
                    organizationID:this.orgaID,
                    password:this.password
                },
            })
            this.$bvModal
                .msgBoxOk("Password Set", {
                title: "New password has been created",
                okVariant: "success",
            })  
            this.password=""
        }, 
        deletePassword(){
            axios.post(`${this.baseURL}/Workflows/deleteWorkflowPassword?access_token=${this.token}`, {
                params: {
                    organizationID:this.orgaID,
                },
            })
            this.$bvModal
                .msgBoxOk("Password Deleted", {
                title: "Password deleted and all instructions were unlocked",
                okVariant: "success",
            })  
            this.password=""
        }             
        }
    }
</script>

<style lang="css" scoped>
.wkPasswordForm{
    max-width: 50%;
    align-content: center;
    margin:auto;
}
</style>