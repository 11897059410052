<template>
    <div>
        <CustomMenu v-on:ChangeCustMenu="changeTab($event)" :usertype="usertype"></CustomMenu>
        <br>
        <!--<div v-if="menu === 'basics'">
            <LogoAdmin :Name1="'Logo'" :Name2="'StartScreen'" :title1="'Organization Logo'" :title2="'Start Screen'" :token="token" :Orga="organization"/>
        </div>-->
        <div v-if="menu === 'paterns'" class="patternParent">
            <PatternsAdmin :token="this.token" :organizationId="this.organization" v-if="!pattCreate"></PatternsAdmin>
            <AddPattern :token="this.token" :organization="this.organization" v-if="pattCreate" v-on:pattAdded="pattAdded2($event)"></AddPattern>
            <div>
                <button v-if="!pattCreate && canCreatePattern" type="button" v-bind:class="{'white-button right-button btn': pattCreate, 'blue-button btn':!pattCreate}" v-on:click="pattCreate = !pattCreate">
                    Create new Pattern
                </button>
            </div>
        </div>
        <div v-if="menu === 'objects'">
             <ThreeDObjects :token="token" :usertype="usertype" :Orga="organization"/>
        </div>
        <div v-if="menu === 'icons'">
            <IndustrialIcons :token="token" :Orga="organization" />
            
        </div>
        <div v-if="menu === 'WfPasswordmanager' && passwordProtectionStatus">
            <WfPasswordmanager :token="token" :orgaID="this.organization" />
        </div>
        <template v-if="menu === 'WfPasswordmanager' && !passwordProtectionStatus">
            <div>
                <h2 class="title mb-3">Password protect your public web instructions</h2>
                <hr>
                <div class="alert alert-primary" role="alert">
                    <p>
                        <i class="bi bi-info-circle-fill mr-1"></i>Public web work instructions can be accessed by anyone who 
                        has the link or who can scan the linked QR code. To protect these work instructions, a password can be set 
                        here that must be entered to unlock the work instruction. This can be especially useful if people in your 
                        company have access to the QR codes who are not allowed to access work instructions.
                    </p>
                    <p class="mb-0">
                        If you are interested in this functionality, please
                        <a
                            href="https://www.ar-giri.com/giri-work-instructions-sales"
                            target="_blank"
                        >
                            schedule an appointment with our service team!
                        </a>
                    </p>
                </div>
            </div>            
        </template>
        <template v-if="menu === 'WhiteLabel' && whiteLabelStatus && usertype !== 'admin'">
            <WhiteLabel :organisationId="this.organization" :token="token" />
        </template>
        <template v-if="menu === 'WhiteLabel' && !whiteLabelStatus">
            <div>
                <h2 class="title mb-3">White-label feature</h2>
                <p>You can customize your colors in this section and upload your company logo.</p>
                <hr>
                <div class="alert alert-primary" role="alert">
                    <p>
                        <i class="bi bi-info-circle-fill mr-1"></i> It is possible to customize GIRI work instructions (shared via QR code web links)
                        with your colors, custom logo and a custom page footer.
                    </p>
                    <p class="mb-0">
                        If you are interested in these customization options, please 
                        <a
                            href="https://www.ar-giri.com/giri-work-instructions-sales"
                            target="_blank"
                        >
                            schedule an appointment with our service team!
                        </a>
                    </p>
                </div>
            </div>
        </template>
        <!--<p>{{this.organization}}</p>-->
        
    </div>
</template>

<script>
import LogoAdmin from '@/components/Tools/LogoAdmin.vue'
import CustomMenu from '@/components/Menus/CustomMenu.vue'
import ThreeDObjects from '@/components/Tools/ThreeDObjects.vue'
import PatternsAdmin from '@/components/Tools/PatternsAdmin.vue'
import AddPattern from '@/components/Tools/AddPattern.vue'
import IndustrialIcons from '@/components/Tools/IndustrialIcons.vue'
import WfPasswordmanager from '@/components/AdminTools/WfPasswordmanager.vue'
export default {
    components:{
        'LogoAdmin': LogoAdmin,
        'CustomMenu': CustomMenu,
        'PatternsAdmin' : PatternsAdmin,
        'AddPattern' : AddPattern,
        'ThreeDObjects' : ThreeDObjects,
        'IndustrialIcons': IndustrialIcons,
        'WfPasswordmanager':WfPasswordmanager,
        WhiteLabel: () => import('../WhiteLabel/WhiteLabel.vue')
    },
    data(){
        return{
            pattCreate:false,
            menu:"paterns"
        }
    },
    computed:{
        canCreatePattern(){
            return this.$accessControlStore.permissions.CREATE_PATTERN;
        }
    },
    props:{
        token:String,
        organization:String,
        usertype:String,
        whiteLabelStatus: {
            type: Boolean,
            required: true,
            default: false,
        },
        passwordProtectionStatus: {
            type: Boolean,
            required: true,
            default: false,
        }
    },
    methods:{
        changeTab(nxt){
            this.menu = nxt;
        },
        pattAdded2(ev){
            this.pattCreate=false;
        }
    }
}
</script>

<style lang="css" scoped>
h2.title {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-bottom: 50px;
}
.patternParent{
    position: relative;
}
.blue-button, .white-button{
    padding: 10px 15px !important;
    margin-top: 10px !important;
    border: none !important;
    border-radius: 3px !important;
}
.right-button{
    position: absolute;
    right: 120px;
    bottom:-70px;
}
.blue-button{
    background-color: #156E8E !important;
    color: #fff !important;
}
.blue-button,.white-button, .blue-button:active, .blue-button:hover, .blue-button:focus{
    border: 1px solid #156E8E !important;
}
.white-button{
    background-color: #fff !important;
    color: #156E8E !important;
}
</style>