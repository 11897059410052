<template><!--LOGIN SECTION-->
    <div>
        <div class="d-flex justify-content-end"> 
            <div class="mx-auto">
                <div id="pswrcvr" style="display:block;">
                    <ResetPassword v-if="currentScreen === 'resetPassword'"></ResetPassword><br>
                </div>
                <form v-if="currentScreen === 'login'" id="loginForm" style="display:block;">
                    <h1 align="center">User Management</h1>
                    <br>
                    <input type="text" class="form-control" placeholder="Name" v-model="username">
                    <br>
                    <input type="password" @keyup.enter="login" class="form-control mb-2" placeholder="Password" v-model="password">
                    <p @click="goToRequestPassword" class="custom-link">Forgot Password?</p>
                    <button type="button" class="btn btn-primary btn-block" id="loginBtn" v-on:click="login" >Login</button>
                </form>
                <div v-if="currentScreen === 'login' && serverFeatures.SSO" style="display: block; margin-top: 5%;">
                    <button class="btn btn-primary btn-block" v-on:click="getAuthToken">{{ SsoButtonMessage }}</button>
                </div>
                
            </div>
        </div>
        <stack-modal :show="showLoginError" title="Error 💥" :saveButton=saveButtonDefaults :cancelButton=cancelButtonDefaults @close="showLoginError=false">
        Wrong username, password or not sufficient permissions, please try again
        </stack-modal>
    </div>
</template>

<script>
    import axios from 'axios'
    import StackModal from '@innologica/vue-stackable-modal'
    import ResetPassword from '@/components/Settings/ResetPassword'

    export default{    
        name: "StackableModalDemo",
        components: { 
            StackModal,
            ResetPassword,
        },      
        props:{
            prop:['test']
        },
        data (){
            return{
                SsoButtonMessage:"Other login methods",
                serverFeatures:{},
                showLoginError:false,
                address:"to be specified", //loopback access point
                currentScreen: "login",
                username:"",
                password:"",
                usertype:"",
                email:"email@domain.com",
                organization:"",
                saveButtonDefaults:{
                    title: 'Ok',
                    visible: false,
                    btnClass: {'btn btn-primary': true,
                    onClick:"return sampleFunction()"}
                },
                cancelButtonDefaults:{
                    title: 'Ok',
                    visible: true,
                    btnClass: {'btn btn-secondary': true}
                }
            }
        },
        beforeMount(){
            axios.get(this.$address+'api/utilities/serverFeatures').then(res=>{                 
                this.serverFeatures = res.data.result ;
                this.SsoButtonMessage = this.serverFeatures.SSO_LOGIN_TEXT
            })
        },
        created() {
            if (this.$route.query.rectoken) this.currentScreen = "resetPassword";
        },
        methods:{
            sampleFunction()
            { 
                alert("abc"); 
                return false;
            },

            async login(){
                try {
                    const credentials = this.formatCredentials(this.username, this.password);
                    const loginResponse = await this.performLogin(credentials);

                    if (loginResponse) {
                        console.log("Login response: ", loginResponse);
                        this.token = loginResponse.id;
                        this.userId = loginResponse.userId;
                        this.$accessControlStore.setToken(this.token);

                        this.$emit('getIdToken', this.userId);

                        const userResponse = await this.fetchUserDetails(this.userId, this.token);

                        if (this.isAuthorizedUser(userResponse)) {
                            
                            this.storeUserDetails(userResponse, this.token);

                            await this.$accessControlStore.fetchFeatures();

                            if (this.$accessControlStore.organizationRoleId && this.$accessControlStore.features.customRoles) {
                                await this.$accessControlStore.fetchPermissions();
                                this.$accessControlStore.startPolling();
                            }
                            
                            this.$emit("changeTokenValue", this.token, this.$address);
                            this.$emit("SetUpUsertype", userResponse);
                            this.changeScreenVisibility(false, false);

                        } else {
                            console.log("Access denied for Werker");
                            this.showLoginError = true;
                        }
                    }
                } catch (error) {
                    console.log("Login error:", error);
                    this.showLoginError = true;
                }       
            },
            
            async performLogin(credentials) {
                try {
                    const response = await axios.post(`${this.$address}api/UserAccounts/login`, credentials);
                    return response.data;
                } catch (error) {
                    throw new Error("Login failed");
                }
            },

            async fetchUserDetails(userId, token) {
                try {
                    const response = await axios.get(`${this.$address}api/UserAccounts/${userId}?access_token=${token}`);
                    return response.data;
                } catch (error) {
                    throw new Error("Failed to fetch user details");
                }
            },

            async getAuthToken(){
                try {
                    const response = await axios.get(this.$address+'api/auth0s/authenticateURL');
                    window.location.href = (response.data);
                } catch (error) {
                    console.log(err);
                }
            },

            async storeOrgFeatures(token) {
                try {
                    const response = await axios.get(this.$address + "api/Organisations?access_token=" + token);

                    if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                        
                        const organizationData = response.data[0];

                        const featuresToUpdate = {
                            analyticsStatus: organizationData.analyticsStatus,
                            autoTranslate: organizationData.autoTranslate,
                            jobDoneStatus: organizationData.jobDoneStatus,
                            worldMapStatus: organizationData.worldMapStatus,
                            visibilityGroups: organizationData.visibilityGroups,
                            whiteLabelStatus: organizationData.whiteLabelStatus,
                            passwordProtectionStatus: organizationData.passwordProtectionStatus,
                            customRoles: organizationData.customRoles,
                        };

                        
                        this.$accessControlStore.setFeatures(featuresToUpdate);
                    }

                } catch (error) {
                    console.log("Failed to store organization features" + error);
                }
            },

            storeUserDetails(userResponse, token) {
                this.$emit("setUserDetails", {
                    token: token,
                    userType: userResponse.usertype,
                    organizationId: userResponse.organizationId,
                });
                
                if (userResponse.organizationRoleId)
                    this.$accessControlStore.setOrganizationRoleId(userResponse.organizationRoleId);

            },

            formatCredentials(username, password) {
                const emailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                const formattedUsername = username.trim().replace(/\s/g, '');
                const formattedPassword = password.trim();

                return emailFormat.test(formattedUsername)
                    ? { email: formattedUsername.toLowerCase(), password: formattedPassword }
                    : { username: formattedUsername, password: formattedPassword };
            },

            changeScreenVisibility(showLoginForm = true, showLogoutForm = false) {
                document.getElementById("loginForm").style.display = showLoginForm ? "block" : "none";
                document.getElementById("pswrcvr").style.display = showLoginForm ? "block" : "none";
            },

            isAuthorizedUser(userResponse) {
                const authorizedRoles = ["admin", "manager", "orgAdmin", "demoAdmin"];
                return authorizedRoles.includes(userResponse.usertype);
            },

            ifRecovery(){
                return !(this.$route.query.rectoken == null || this.$route.query.rectoken == undefined || this.$route.query.rectoken == "");
            },

            changeCurrentScreen(value = "login") {
                this.currentScreen = value;
            },

            goToRequestPassword() {
                const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (mailformat.test(this.username)) {
                    this.$router.push({ path: 'reset-password', query: { email: this.username } });
                } else {
                    this.$router.push('/reset-password');
                }
            }
        }
    }
</script>
<style lang="css" scoped>
p.custom-link {
  color: #0093FF;
  text-align: right;
}
p.custom-link:hover {
  cursor: pointer;
  cursor: hand;
}
</style>