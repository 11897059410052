<template>
<div class="container my-3">
    <h4 style="margin-top:10px; font-weight:lighter">3D MODELS MANAGER</h4>
    <br>
    <div class="container">
        <div class="row">
            <div class="col-md-8" v-bind:key="this.componentKey">
                <b-table
                    :items="groupedObjects"
                    :fields="fields"
                    stacked="md"
                >
                    <template #cell(ios)="row">
                        <b-form-checkbox v-if="row.item.ios" size="lg" value="1" checked="1" disabled />
                    </template>
                    <template #cell(android)="row">
                        <b-form-checkbox v-if="row.item.android" size="lg" value="1" checked="1" disabled />
                    </template>
                    <template #cell(isActive)="row">
                        <b-form-checkbox
                            @change="toggleStatus(row.item.isActive, row.item.rowInfo)"
                            v-model="row.item.isActive"
                            switch
                            size="lg"
                            :disabled="!canManageARObject"
                        />
                    </template>
                    <template #cell(actions)="row">
                        <button
                            v-if="!row.item.isDefault"
                            @click="deleteObjs(row.item.symbolID, row.item.rowInfo)"
                            class="btn btn-sm btn-dark"
                        >
                            <i class="bi bi-trash-fill"></i>
                        </button>
                    </template>
                </b-table>
            </div>
            <div v-if="loaded && canManageARObject" class="col-md-4">
                <dragNdrop
                    :token="token"
                    :Orga="Orga"
                    :defaultObjs="defaultObjs"
                    @object_adeed="addObjectToList"
                />
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import axios from 'axios'
    import dragNdrop from '@/components/Components/dragAndDrop.vue'
    export default{
        data(){
            return{
                API : 'https://localhost'+'/api',
                fields: [
                    { key: 'symbolID', label: 'Name' },
                    { key: 'ios', label: 'iOS' },
                    { key: 'android', label: 'Android' },
                    { key: 'isActive', label: 'Active' },
                ],
                defaultObjs: [],
                groupedObjects: [],
                loaded: false,
                objects: [],
                componentKey: 0
            }
        },
        mounted(){
            var domain=self.location.host;
            if(domain =="localhost" || domain=="giri" || domain=="127.0.0.1"){
                console.log("On premise server");
                this.API="https://localhost/api";
            }else{
                console.log("Cloud server");
                this.API="https://"+self.location.host+"/api"
            }
            this.getObjects();

            if (this.canDeleteARObject) {
                this.fields.push({ key: 'actions', label: 'Delete' });
            }
        },
        components: { dragNdrop },
        props:{
            token: String,
            Orga:String,
            usertype:String
        },
        computed: {
            canManageARObject(){
                return this.$accessControlStore.permissions.MANAGE_OBJECT;
            },
            canDeleteARObject(){
                return this.$accessControlStore.permissions.DELETE_OBJECT;
            }
        },
        methods: {
            forceRerender(){
                this.componentKey += 1;
            },
            getObjects:function(){
                this.objects=[];
                axios
                .get(this.API + '/objects?access_token='+ this.token+ "&filter[where][organizationID]=" + this.Orga)
                .then(response => {
                    var info = response.data;
                    //show only patterns that are not delete in UI
                    info.forEach(object => {
                        if(!object.deleted){
                            object.toDelete = false;
                            this.objects.push(object);
                        }
                    });
                    for (const arObject of this.objects) {
                        if (arObject.filepath === 'Files/giri_objects') continue;
                        const arrNames = arObject.symbolID.split(" ");
                        const suffix = arrNames[arrNames.length - 1];
                        if (suffix === 'ios' || suffix === 'android') arrNames.pop();
                        arObject.symbolID = arrNames.join(" ");
                    }
                    const groupedObjects = this.objects.reduce((accum, element) => {
                        const { symbolID, filepath, isActive, opSystem } = element;
                        const objectBody = {
                            symbolID: '',
                            rowInfo: [],
                            android: false,
                            ios: false,
                            isDefault: true,
                            isActive: true,
                        };
                        if (!accum[symbolID]) accum[symbolID] = objectBody;
                        accum[symbolID].symbolID = symbolID;
                        accum[symbolID].rowInfo.push(element);
                        if (opSystem === 'android') accum[symbolID].android = true;
                        if (opSystem === 'ios') accum[symbolID].ios = true;
                        if (filepath === 'Files/giri_objects') accum[symbolID].isDefault = false;
                        if (!isActive) accum[symbolID].isActive = false;
                        return accum;
                    }, {});
                    this.groupedObjects = Object.values(groupedObjects);
                    this.defaultObjs = this.groupedObjects.filter(item => item.isDefault);
                    this.defaultObjs = this.defaultObjs.map(item => item.symbolID);
                    this.loaded = true;
                });
            },
            async toggleStatus(isActive, rowInfo) {
                try {
                    if (typeof isActive !== 'boolean') return;
                    for (const arObject of rowInfo) {
                        const { id } = arObject;
                        await axios.patch(`${this.API}/objects/${id}?access_token=${this.token}`, { isActive });
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async deleteObjs(symbolID, rowInfo) {
                const h = this.$createElement;
                const messageVNode = h('div', [
                    h('p', 'Deleting 3D object completely removes them from the database.'),
                    h('p', `If ${symbolID} has already been used in a instruction, this will no longer be displayed there.`)
                ]);
                this.$bvModal.msgBoxConfirm([messageVNode], {
                    title: 'Warning',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO'
                })
                .then(async (value) => {
                    if (!value) return;
                    const objectsToRemove = rowInfo.filter(obj => obj.filepath === 'Files/giri_objects');
                    for (const arObject of objectsToRemove) {
                        const { id } = arObject;
                        await axios.delete(`${this.API}/objects/${id}?access_token=${this.token}`);
                        await axios.delete(`${this.API}/Files/giri_objects/files/${id}?access_token=${this.token}`);
                    }
                    const idx = this.groupedObjects.findIndex((item) => item.symbolID === symbolID);
                    this.groupedObjects.splice(idx, 1);
                })
                .catch(() => {
                    this.$bvModal.msgBoxOk('Error when trying to delete this asset', {
                        title: 'Error',
                        okVariant: 'danger'
                    });
                });
            },
            addObjectToList(object){
                this.objects.push(object);
                this.getObjects();
                this.forceRerender();
            },
        }
    }

</script>

<style lang="css" scoped>
.spacing{
    margin-bottom: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #156E8E;
}

input:focus + .slider {
  box-shadow: 0 0 1px #156E8E;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>