import { defineStore } from "pinia";
import { UserPermissions } from "../constants/UserPermissions";
import { Features } from "../constants/Features";
import axios from "axios";
import Vue from "vue";

export const useAccessControlStore = defineStore("auth", {
  state: () => ({
    token: null,
    permissions: { ...UserPermissions },
    features: { ...Features },
    organizationRoleId: null,
    pollingInterval: null,
    customRoles: false,
    userType: null,
  }),
  actions: {
    setOrganizationRoleId(organizationRoleId) {
      this.organizationRoleId = organizationRoleId;
    },
    setToken(token) {
      this.token = token;
    },
    setPermissions(receivedPermissions) {
      for (let key in receivedPermissions) {
        if (this.permissions.hasOwnProperty(key)) {
          this.permissions[key] = receivedPermissions[key];
        }
      }
    },
    setFeatures(features) {
      this.features = features;
    },
    setCustomRoles(customRoles) {
      this.customRoles = customRoles; 
    },
    setUserType(userType) {
      this.userType = userType; 
    },
    startPolling() {
      if (!this.pollingInterval) {
        this.pollingInterval = setInterval(async () => {
          try {
            await this.fetchPermissions();
            await this.fetchFeatures();
          } catch (error) {
            console.error("Polling error:", error);
          }
        }, 10000);
      }
    },
    stopPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
      }
    },
    clearAuth() {
      this.token = null;
      this.permissions = { ...defaultPermissions };
      this.features = { ...Features };
    },
    async fetchPermissions() {
      try {
        const address = Vue.prototype.$globalVue.$address;
        if (!this.features.customRoles) {
          this.features = { ...Features };
        }
        const response = await axios.get(
          address +
            "api/organizationRoles/getOrganizationPermissions?access_token=" +
            this.token,
          { params: { organizationRoleId: this.organizationRoleId } }
        );
        if (response.data && response.data.permissions) {
          this.setPermissions(response.data.permissions);
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
        this.stopPolling();
      }
    },
    async fetchFeatures() {
      try {
        const address = Vue.prototype.$globalVue.$address;
        const response = await axios.get(
          address + "api/Organisations?access_token=" + this.token
        );
        if (
          response.data &&
          Array.isArray(response.data) &&
          response.data.length > 0
        ) {
          const receivedData = response.data[0];
          const filteredFeatures = {};

          for (const key in Features) {
            if (receivedData.hasOwnProperty(key)) {
              filteredFeatures[key] = receivedData[key];
            }
          }

          this.setFeatures(filteredFeatures);
        }
      } catch (error) {
        console.error("Error fetching features:", error);
        this.stopPolling();
      }
    },
  },
  getters: {
    hasPermission: (state) => (permission) => {
      return !!state.permissions[permission];
    },
    hasFeature: (state) => (feature) => {
      return !!state.features[feature];
    },
    canAccess: (state) => (permission, feature) => {
      const hasPerm = !!state.permissions[permission];
      const hasFeat = !!state.features[feature];
      return hasPerm && hasFeat;
    },
    hasCustomRoles: (state) => {
      return state.customRoles;
    },
    getUserType: (state) => {
      return state.userType;
    },
  },
});
