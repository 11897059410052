<template>
    <div>
        <h2 class="title">Patterns menu</h2>
        <div class="container" v-bind:key="this.componentKey">
            <div class="row">
                <div class="col-1">
                    <div class="row justify-content-center">
                        <h5>Default</h5>
                    </div>    
                </div>
                <div class="col-5">
                    <div class="row">
                        <h5>Patterns</h5>
                    </div>  
                </div>
                <div class="col-1" >
                    <div class="row justify-content-center" v-if="canDeletePattern">
                        <h5>Delete</h5>
                    </div>  
                </div>
                <div class="col-1">
                    <div class="row">
                        <h5>&nbsp;Active</h5>
                    </div>  
                </div>
                <div class="col-1"> 
                </div>
            </div>
            <div v-for="(item, index) in pList" v-bind:key="index">
                <div class="row verticalCenter">
                    <div class="col-1 verticalCenterBox justify-content-center">
                        <input class="radioButton"  type="radio" name="exampleRadios" v-on:click="changeDefault(index)" :checked="item.default" :disabled="!(canDeletePattern || canCreatePattern)">
                    </div>
                    <div class="col-5 verticalCenterBox">
                        <p class="pattern">{{item[item.defaultLanguage] | unescapeQuotes}}</p>
                    </div>
                    
                    <div class="col-1 verticalCenterBox justify-content-center">
                    <b-button
                        v-if="!item.default && canDeletePattern"
                        variant="dark"
                        v-on:click="item.toDelete = true"
                        class="d-flex justify-content-center align-items-center p-0"
                        style="width: 35px; height: 35px;"
                    >
                        <i class="bi bi-trash-fill" style="font-size: 1.25rem;"></i>
                    </b-button>
                    <div v-else class="col-1 verticalCenterBox"><img src="../../../../assets/white.png" width="35px"/></div>
                </div>
                    <div class="col-1 verticalCenterBox">
                             <div class="text-center">
                                <b-form-checkbox
                                    v-model="item.active"
                                    switch
                                    size="lg"
                                    :disabled="!(canDeletePattern || canCreatePattern) || chngState"
                                    @change="changeState(index)"
                                    style="transform: scale(1.5);"
                                />
                             </div>
                            
                    </div>
                    <div class="col verticalCenterBox">
                        <p class="onOff">{{item.active?"On":"Off"}}</p>
                    </div>
                </div>

                <div v-if="item.toDelete">
                    <stackAccept :id="item.id" :name="item[item.defaultLanguage]" :elementType="'Patterns'" @boolDelete="deletePattern" />
                </div>
            </div>
        </div>

        <div>
            <stack-modal :show="showError" title="Error 💥" :saveButton=saveButtonDefaults :cancelButton=cancelButtonDefaults @close="showError=false">
                {{errorMassage}}
            </stack-modal>
        </div>
        <!--<p>{{pList}}</p>
        <p>{{organizationId}}</p>-->


    </div>
</template>

<script>
    import axios from 'axios'
    import StackModal from '@innologica/vue-stackable-modal'
    import stackAccept from '@/components/Components/stackAccept.vue'
    export default {
        components:{
            StackModal,
            'stackAccept':stackAccept
        },  
        props:{
            token:String,
            organizationId:String
        },
        data(){
            return{
                pList: [],
                address:"",
                chngState:false,
                lang: "en",
                componentKey : 0,
                saveButtonDefaults:{
                    title: 'Ok',
                    visible: false,
                    btnClass: {'btn btn-primary': true,
                    onClick:"return sampleFunction()"}
                },
                cancelButtonDefaults:{
                    title: 'Ok',
                    visible: true,
                    btnClass: {'btn btn-secondary': true}
                },
                showError:false,
                errorMassage:"",
                deleting:false,
                idDelete:"",
                nameDelete:""
            }
        },
        computed: {
            canCreatePattern() {
                return this.$accessControlStore.permissions.CREATE_PATTERN;
            },
            canDeletePattern(){
                return this.$accessControlStore.permissions.DELETE_PATTERN;
            }
        },
        methods:{
            forceRerender(){
                this.componentKey += 1;
            },
            changeState(ind){
                this.chngState = !this.chngState;
                const args ={
                    id:this.pList[ind].id,
                    active: this.pList[ind].active
                }
                axios.patch(this.$address + "api/Patterns?access_token="+this.token, args).then(
                    response=>{
                        this.chngState = false;
                    },error=>{
                        this.chngState = false;
                    }
                );
            },
            changeDefault(ind){
                this.pList.forEach(element => {
                    if(element.default){
                        const arg = {
                            id:element.id,
                            default:false
                        };

                        axios.patch(this.$address + "api/Patterns?access_token="+this.token, arg).then(
                            response=>{
                                element.default = false;
                            }
                        );
                    }
                });


                const args ={
                    id:this.pList[ind].id,
                    default:true
                }
                axios.patch(this.$address + "api/Patterns?access_token="+this.token, args).then(
                    response=>{
                        this.pList[ind].default = !this.pList[ind].default;

                    }
                );
            },
            deletePattern(del, id){
                const values = this.pList.filter(a=>a.id==id);

                if(values.length <= 0)
                    return;

                const itm = values[0];
                const patternToRemove = this.pList.indexOf(itm);
                
                if(!del){
                    this.pList[patternToRemove].toDelete = false;
                    return;
                }
                
                    

                if(itm.default==true){
                    this.showError=true;
                    this.errorMassage="The default pattern cannot be deleted."
                    return;
                }

                axios.delete(this.$address + "api/Patterns/"+id+"?access_token="+this.token).then(
                    response =>{
                        this.pList.splice(patternToRemove, 1);
                    }, 
                    error =>{
                        console.log("Pattern could not be deleted");
                    }
                );
                this.forceRerender();
                return;
            }
            
        },
        mounted(){
            //recursion to get names
            var pattName = function(headV, index){
                axios.get(this.$address + "api/Headers?id="+headV+"&access_token="+this.token).then(
                    resp=>{
                        this.pList[index].name+=resp.data[this.lang];
                        console.log(pList);
                    }
                )
            }

            axios.get(this.$address + 'api/Patterns?access_token='+this.token+"&filter[where][organisationID]=" + this.organizationId).then(
                response=>{
                    var info = response.data;
                    //show only patterns that are not delete in UI
                    info.forEach(pattern => {
                        if(!pattern.deleted){
                            pattern.toDelete = false;
                            this.pList.push(pattern);
                        }
                    });
                }
            );
        },
        filters: {
            unescapeQuotes(text) {
                return text.replace(/\\(.)/g, '$1');
            }
        }
    }
</script>

<style lang="css" scoped>
    .title{
        margin-bottom: 50px;
        font-weight: 300;
        font-family: 'Open Sans', sans-serif;
        color: #000;
    }
    .radioButton, .radioButton:checked{
        color: #156E8E;
        background-color: #156E8E;
        border-color: #156E8E;
    }
    .pattern, .radioButton, .switch, .onOff{
        margin: 0px !important;
        padding: 0px !important;
    }
    .verticalCenterBox{
        display: flex;
        align-items:center;
        margin-bottom: 10px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #156E8E;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
</style>>
